body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: ArchivoNarrow-Regular;
  src: url(../public/ArchivoNarrow-Regular.ttf);
}
.ArchivoNarrow-Regular {
  font-family: ArchivoNarrow-Regular;
}

#factors {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: '900px';
}

#factors td, #factors th {
  border: 1px solid #ddd;
  padding: 4px;
}

#factors tr:nth-child(even){background-color: #f2f2f2;}

#factors tr:hover {background-color: #ddd;}

#factors th {
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #333f4f;
  color: white;
}


.strategy_results_container {
  border: 1px solid lightgray;
  height: 780px;
  overflow: auto;
}
.strategy_backtest_container {
  border: 1px solid lightgray;
  /* height: 520px; */
  overflow: auto;
}

#strategy_results {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  /* width: '500px'; */
  font-family: 'ArchivoNarrow-Regular';
}

#strategy_results td, #strategy_results th {
  border: 1px solid #ddd;
  font-family: 'ArchivoNarrow-Regular';
  padding: 4px;
}

#strategy_results thead {
  margin: 0;
  position: sticky;
  top: 0;
}

#strategy_results tr:nth-child(even){background-color: #f2f2f2;}

#strategy_results tr:hover {background-color: #ddd;}

#strategy_results th {
  font-family: 'ArchivoNarrow-Regular';
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #333f4f;
  color: white;
}

.pointer {
  cursor: pointer;
}





/* TanStackTable */



.tanstack_container {
  border: 2px solid lightgray;
  max-height: calc(100vh - 120px);
  overflow-x: scroll;
  position: relative;
}

.tanstack_table { 
  border-collapse: collapse;
  border-spacing: 0;
}

.tanstack_table th {
  /* border-collapse: collapse; */
  border: 1px solid #ddd;
  padding: 4px;
  color: white;
  background-color: #333f4f;
  position: relative;
}

.tanstack_table td {
  padding: 4px;
}

.tanstack_table thead {
  padding: 0;
  margin: 0;
  position: sticky;
  top: 0;
  opacity: 1;
  z-index: 15;
}

.tanstack_table tr:nth-child(even){background-color: #f2f2f2;}
.tanstack_table tr:hover {background-color: #ddd;} 


/* .tanstack_table td:nth-child(1){background-color: #f2f2f2;} */
.tanstack_table tr:nth-child(odd) > td:first-child {
  background-color: white;
}
.tanstack_table tr:nth-child(even) > td:first-child {
  background-color: #f2f2f2;
}